<template>

 <div>
   
   <b-form-datepicker boundary="window" no-key-nav="true" nav-button-variant="primary" reset-button-variant="btn btn-danger" @input="ondateselect()" :hide-header="true" :label-help="''" style="width: 50%; border-color: var(--N200);" id="example-datepicker" v-model="proximityrisk" class="mb-2" :date-format-options="{  month: '2-digit', day: '2-digit', year: 'numeric' }" calendarLocale="swe" locale="en" reset-button >  
      
      </b-form-datepicker>

 </div>
</template>	
<style type="text/css">
  #app{
  background-color: #fff;
}


  td{
    text-align: center;
  }

  .action{
    font-size: 24px;
  }

  .trait{
    font-size: 18px;
    font-weight: bold;
  }
</style>

<script type="text/javascript"></script>
<script>


  import Userapi from '@/services/api/users.js'

  //import FroalaEditor from 'froala'
  import FroalaEditor from 'froala-editor'

	export default{
		data(){
			return{
          proximityrisk :"",
          userid : null,
          users : [],
          pick :"",
          froalaconfig : null,
          linkInitControls : {},
          message : ""
        }
        },

        computed: {},
        filters: {},
        mounted() {

          $('#preloader').addClass('hide')

        let self = this

        //this.froalaconfig = this.$store.state.config

       // this.linkInitControls.initialize()


        new FroalaEditor('div#froala-editor')


        },
        methods: {

           initializefroala: function(initControls) {
            this.linkInitControls = initControls;
          },

        }
      }
</script>